.phone-form {
  position: relative;
  @media #{$small-desktop} {
    width: 240px;
  }
  @media #{$mobile} {
    width: 100%;
  }
  &__title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 22px;
    @media #{$small-desktop} {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
  &__body {
    margin-bottom: 14px;
    display: flex;
    @media #{$small-desktop} {
      display: block;
    }
  }
  &__message {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    @media #{$small-desktop} {
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__submit {
    @media #{$small-desktop} {
      width: 100%;
    }
    @media #{$big-tablet} {
      height: 72px;
    }
  }
  &__content,
  &__message {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  &__input {
    padding: 0 10px;
    border-radius: 10px;
    font-weight: 400;
    width: 240px;
    height: 72px;
    font-size: 17px;
    line-height: 24px;
    border: 2px solid rgba(188, 195, 208, 0.5);
    transition: border-color 0.3s ease-in-out;
    margin-right: 24px;
    @media #{$small-desktop} {
      margin-right: 0;
      margin-bottom: 16px;
      width: 100%;
    }
    &:focus {
      border-color: $background-light-primary-elevated;
    }
  }
  &__message {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    opacity: 0;
    visibility: hidden;
  }
  &__comment {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-dark-secondary;
    a {
      color: $text-dark-primary-link;
    }
  }
  &--submitted {
    .phone-form {
      &__content {
        opacity: 0;
        visibility: hidden;
      }
      &__message {
        //transition-delay: .3;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
