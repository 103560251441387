@import "./normalize"
@import "./vars"
@import "./mixins"
@import "./fonts"
@import "./sprite_generated"

// From libs:
//@import "../libs/LIB_NAME/path/to/style.scss"

*
  box-sizing: border-box
  // min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()
  &:before,&:after
    box-sizing: border-box

body
  display: flex
  flex-direction: column
  background-color: #000
  font-family: "MTS Sans Web"
  font-weight: 500
  color: #fff
  width: 100%
  height: 100%
  min-height: 100vh
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.wrapper
  width: 100%
  max-width: 850px
  min-width: 320px
  margin: 0 auto
  padding: 0 40px

.container
  margin: 0 auto
  padding: 0 30px
  @media #{$big-tablet}
    padding: 0 40px
  @media #{$mobile}
    padding: 0 20px
.mobile-br
  display: none
  @media #{$mobile}
    display: initial
.small-desktop-br
  display: none
  @media #{$small-desktop}
    display: initial
