.appstores {
  display: flex;
  &__unit {
    width: 136px;
    height: 40px;
    background-size: cover;
    margin-right: 19px;
    @media #{$big-tablet} {
      width: 101px;
      height: 30px;
    }
    &:last-child {
      margin-right: 0;
    }
    &--appStore {
      width: 135px;
      background-image: url(../images/hero/appstore.svg);
      @media #{$big-tablet} {
        width: 101px;
      }
    }
    &--googlePlay {
      background-image: url(../images/hero/googlePlay.svg);
    }
    &--appGallery {
      background-image: url(../images/hero/appGallery.svg);
    }
  }
  &--big {
    .appstores__unit {
      width: 204px;
      height: 60px;
      @media #{$small-desktop} {
        width: 135px;
        height: 40px;
      }
    }
  }
}
