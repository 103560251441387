@font-face {
  font-family: "MTS Sans Web";
  src: url("MTSSans-Bold__W.eot");
  src: url("MTSSans-Bold__W.eot?#iefix") format("embedded-opentype"),
    url("MTSSans-Bold__W.woff2") format("woff2"),
    url("MTSSans-Bold__W.woff") format("woff"),
    url("MTSSans-Bold__W.ttf") format("truetype"),
    url("MTSSans-Bold__W.svg#MTSSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "MTS Sans Web";
  src: url("MTSSans-Regular__W.eot");
  src: url("MTSSans-Regular__W.eot?#iefix") format("embedded-opentype"),
    url("MTSSans-Regular__W.woff2") format("woff2"),
    url("MTSSans-Regular__W.woff") format("woff"),
    url("MTSSans-Regular__W.ttf") format("truetype"),
    url("MTSSans-Regular__W.svg#MTSSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MTS Sans Web Ultrawide";
  src: url("MTSSans-UltraWide__W.eot");
  src: url("MTSSans-UltraWide__W.eot?#iefix") format("embedded-opentype"),
    url("MTSSans-UltraWide__W.woff2") format("woff2"),
    url("MTSSans-UltraWide__W.woff") format("woff"),
    url("MTSSans-UltraWide__W.ttf") format("truetype"),
    url("MTSSans-UltraWide__W.svg#MTSSans-UltraWide") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "MTS Sans Web";
  src: url("MTSSans-Medium__W.eot");
  src: url("MTSSans-Medium__W.eot?#iefix") format("embedded-opentype"),
    url("MTSSans-Medium__W.woff2") format("woff2"),
    url("MTSSans-Medium__W.woff") format("woff"),
    url("MTSSans-Medium__W.ttf") format("truetype"),
    url("MTSSans-Medium__W.svg#MTSSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
