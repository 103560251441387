.popup {
  padding: 30px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  background-color: rgba(29, 32, 35, 0.6);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  @media #{$mobile} {
    padding: 0;
  }
  &__body {
    overflow: hidden;
    max-width: 100%;
    position: relative;
    padding: 69px 71px 108px;
    margin: auto;
    background-color: #282727;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
      0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
      0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
      0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
      0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 40px;
    @media #{$small-desktop} {
      padding: 44px 40px 62px;
    }
    @media #{$mobile} {
      padding: 30px 20px;
    }
  }
  &__close {
    width: 48px;
    height: 48px;
    position: absolute;
    cursor: pointer;
    top: 64px;
    right: 70px;
    z-index: 1;
    @media #{$small-desktop} {
      top: 42px;
      right: 40px;
    }
    @media #{$mobile} {
      top: 15px;
      right: 15px;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 32px;
      height: 32px;
      top: 50%;
      left: 50%;
      height: 4px;
      background-color: #fff;
      border-radius: 2px;
      transition: background-color 0.3s ease-in-out;
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #61b5ea;
      }
    }
  }
  &--shown {
    opacity: 1;
    visibility: visible;
  }
}
