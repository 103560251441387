.qr-code {
  width: 526px;
  height: 526px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  @media #{$small-desktop} {
    width: 419px;
    height: 419px;
    padding-bottom: 40px;
    padding-right: 16px;
  }
  @media #{$mobile} {
    padding-right: 0;
  }
  &__title {
    padding-left: 6px;
    position: relative;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 31px;
    @media #{$small-desktop} {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 35px;
      padding-left: 42px;
    }
  }
  &__content {
    position: relative;
    @media #{$small-desktop} {
      width: 145px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    //border: 1px solid rgba(255, 255, 255, 0.76);
    background: linear-gradient(
      135.26deg,
      rgba(255, 255, 255, 0.11) 14.84%,
      rgba(255, 255, 255, 0.02) 86.01%
    );
    backdrop-filter: blur(28px);
  }
}
