.btn {
  padding: 24px 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;
  @media #{$big-tablet} {
    padding: 14px 30px;
  }
  span {
    z-index: 1;
    position: relative;
  }
  &--big {
    border-radius: 10px;
    height: 72px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    @media #{$big-tablet} {
      font-size: 17px;
      line-height: 24px;
      height: 52px;
      border-radius: 8px;
    }
    @media #{$mobile} {
      height: 44px;
    }
  }
  &--gradient {
    color: #fff;
    position: relative;
    background: $accent;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      transition: opacity 0.3s ease-in-out;
    }
    &:before {
      background: linear-gradient(
        94.55deg,
        #f20520 -3.74%,
        #ca33da 62.26%,
        #2ae9f6 136.83%
      );
    }
    &:after {
      background: linear-gradient(
        94.55deg,
        #2ae9f6 -3.74%,
        #ca33da 62.26%,
        #f20520 136.83%
      );
      opacity: 0;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
    &:active {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 0;
      }
    }
  }
  &--disabled {
    pointer-events: none;
    background: rgba(188, 195, 208, 0.5);
    opacity: 0.6;
    color: $text-light-tertiary;
    &:before,
    &:after {
      opacity: 0;
    }
  }
}
