.hero {
  padding: 20px 0;
  height: 100vh;
  min-height: 548px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
  @media #{$mobile} {
    padding: 0;
    height: initial;
    min-height: 100vh;
    justify-content: flex-start;
  }
  &__wrap {
    position: relative;
    width: 100%;
    display: flex;
    @media #{$mobile} {
      flex-direction: column-reverse;
    }
  }
  &__image {
    pointer-events: none;
    right: -51px;
    top: 50%;
    transform: translateY(-51.7%);
    position: absolute;
    @media #{$big-desktop} {
      right: -24px;
      transform: translateY(-54.7%);
    }
    @media #{$medium-desktop} {
      right: 39px;
      transform: translateY(-56.2%);
    }
    @media #{$small-desktop} {
      right: 67px;
    }
    @media #{$big-tablet} {
      right: 46px;
      transform: translateY(-58.7%);
    }
    @media #{$tablet} {
      right: -34px;
      transform: translateY(-60.2%);
    }
    @media #{$mobile} {
      position: relative;
      top: 0;
      right: 50%;
      transform: translate(57%, -7.8%);
      margin-bottom: calc(-7.8% - 68px);
    }
  }
  &__appstores {
    @media #{$mobile} {
      display: none;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
  }
  &__container {
    width: 100%;
    max-width: 1612px;
    @media #{$big-desktop} {
      max-width: 1420px;
    }
    @media #{$medium-desktop} {
      max-width: 1324px;
    }
    @media #{$small-desktop} {
      max-width: 1228px;
    }
  }
  &__logo {
    margin-bottom: 23px;
    @media #{$medium-desktop} {
      width: 176px;
      margin-bottom: 20px;
    }
    @media #{$big-tablet} {
      width: 132px;
      margin-bottom: 11px;
    }
    @media #{$mobile} {
      width: 110px;
      margin-bottom: 6px;
    }
  }
  &__title {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 36px;
    font-family: "MTS Sans Web Ultrawide";
    @media #{$medium-desktop} {
      font-size: 50px;
      line-height: 50px;
    }
    @media #{$big-tablet} {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 26px;
    }
    @media #{$tablet} {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 22px;
    }
    @media #{$small-mobile} {
      font-size: 28px;
      line-height: 28px;
    }
  }
  &__text {
    max-width: 420px;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 42px;
    @media #{$medium-desktop} {
      font-size: 20px;
      line-height: 24px;
    }
    @media #{$big-tablet} {
      font-size: 17px;
      line-height: 24px;
    }
    @media #{$mobile} {
      font-weight: 400;
      margin-bottom: 52px;
    }
  }
  &__btn {
    width: 380px;
    margin-bottom: 40px;
    @media #{$big-tablet} {
      width: 260px;
    }
    @media #{$mobile} {
      width: 100%;
      margin-bottom: 32px;
    }
  }
  &__phones {
    position: relative;
    background-image: url(../images/hero/phones.png);
    background-size: cover;
    width: 25.9375vw;
    max-width: 498px;
    @include aspect-ratio(160.32064128256513026052104208417%);
    @media #{$big-desktop} {
      width: 28.214285714285714285714285714286vw;
    }
    @media #{$medium-desktop} {
      width: 31.180555555555555555555555555556vw;
    }
    @media #{$small-desktop} {
      width: 31.71875vw;
    }
    @media #{$big-tablet} {
      width: 35.204081632653061224489795918367vw;
    }
    @media #{$tablet} {
      width: 45.182291666666666666666666666667vw;
    }
    @media #{$mobile} {
      width: 80.833333333333333333333333333333vw;
    }
  }
  &__circles {
    width: 100%;
    position: absolute;
    top: 40.5%;
    left: 52.2%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    @media #{$mobile} {
      top: 56.5%;
    }
  }
  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    box-shadow: -5px 8px 13px 1px rgb(0 0 0 / 10%);
    background: radial-gradient(
      circle at 100% 43%,
      rgba(251, 149, 248, 1) 0%,
      rgba(248, 110, 243, 1) 25%,
      rgba(185, 111, 229, 1) 47%,
      rgba(152, 112, 220, 1) 58.5%,
      rgba(131, 136, 216, 1) 66%,
      #60bfcf 85%
    );
    width: 50.602409638554216867469879518072%;
    transform: translate(-50%, -50%);
    @include aspect-ratio(100%);
    animation: ripple 10s infinite linear;
    &:nth-child(1) {
      z-index: 4;
      animation: dot 10s linear infinite;
    }
    &:nth-child(3) {
      animation-delay: 0.2s;
    }
    &:nth-child(4) {
      animation-delay: 0.4s;
    }
    &:nth-child(5) {
      animation-delay: 2.5s;
    }
    &:nth-child(6) {
      animation-delay: 2.7s;
    }
    &:nth-child(7) {
      animation-delay: 3.5s;
    }
    &:nth-child(8) {
      animation-delay: 4s;
    }
    &:nth-child(9) {
      animation-delay: 4.5s;
    }
    &:nth-child(10) {
      animation-delay: 6.6s;
    }
    &:nth-child(11) {
      animation-delay: 6.8s;
    }
    &:nth-child(12) {
      animation-delay: 7s;
    }
    &:nth-child(13) {
      animation-delay: 7.2s;
    }
    &:nth-child(14) {
      animation-delay: 8.2s;
    }
  }
}
@keyframes dot {
  0%,
  2%,
  4%,
  6%,
  25%,
  27%,
  29%,
  35%,
  37%,
  40%,
  42%,
  45%,
  47%,
  66%,
  68%,
  70%,
  72%,
  74%,
  82%,
  84% {
    transform: translate(-50%, -50%) scale(1);
  }
  1%,
  3%,
  5%,
  26%,
  28%,
  36%,
  41%,
  46%,
  67%,
  69%,
  71%,
  73%,
  83% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}
@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  1% {
    opacity: 0.9;
  }
  30%,
  100% {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}
.hero-popup {
  &__title {
    font-size: 52px;
    line-height: 60px;
    margin-bottom: 67px;
    @media #{$small-desktop} {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 40px;
    }
    @media #{$mobile} {
      font-size: 24px;
      line-height: 24px;
      padding-right: 60px;
    }
    @media #{$small-mobile} {
      font-size: 20px;
    }
    span {
      display: inline-block;
      position: relative;
      background: linear-gradient(
        to right,
        rgba(0, 201, 246, 1) 0%,
        rgba(241, 22, 238, 1) 75%,
        rgba(241, 22, 22, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:after {
        width: 100%;
        bottom: -8px;
        height: 3px;
        content: "";
        position: absolute;
        left: 0;
        background: linear-gradient(270.68deg, #f116ee 0%, #00c9f6 101.71%);
        @media #{$small-desktop} {
          bottom: -14px;
        }
        @media #{$mobile} {
          bottom: -10px;
        }
        @media #{$small-mobile} {
          //bottom: -8px;
        }
      }
    }
  }
  &__body {
    display: flex;
    background-image: url(../images/hero/popup-bg.svg);
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__wrap {
    width: 1120px;
    max-width: 100%;
    position: relative;
    @media #{$small-desktop} {
      width: 620px;
    }
    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
  }
  &__qr-code {
    position: absolute;
    top: 33px;
    right: 13px;
    @media #{$small-desktop} {
      top: 60px;
      right: -56px;
    }
    @media #{$mobile} {
      position: relative;
      top: 0;
      right: 0%;
    }
  }
  &__phone-form {
    margin-bottom: 138px;
    @media #{$small-desktop} {
      margin-bottom: 36px;
    }
  }
  &__appstores {
    @media #{$mobile} {
      align-items: center;
      flex-direction: column;
      .appstores__unit {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
